<template>
  <event-list :eventType="3" />
</template>

<script>
import EventList from '@/components/EventList.vue'

export default {
  name: 'banner',
  components: {
    EventList
  },
  data() {
    return {}
  },
  created() {},
  methods: {}

}
</script>

<style>
</style>